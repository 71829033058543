import React from "react";
import styled from "styled-components";

const Contact = () => {
  const ContactContainer = styled.div`
    gap: min(3vw, 20px);
    display: flex;
    flex-direction: column;
  `;

  const GreenBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 5px solid #00c22d;
    border-radius: 10px;
    width: 75vw;
    max-width: 800px;
    height: auto;
    padding: 10px;
  `;

  const Image = styled.img`
    width: 90%;
    height: auto;
  `;

  return (
    <ContactContainer>
      <GreenBox>
        <Image src="/img/contact/email.svg" alt="claire@claireandoscar.com" />
      </GreenBox>
      <GreenBox>
        <Image
          src="/img/contact/nospam.svg"
          alt="No spam! We promise, updates only!"
        />
      </GreenBox>
    </ContactContainer>
  );
};

export default Contact;
