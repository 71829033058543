import React, { useState } from "react";
import styled from "styled-components";

const Questions = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const logoImages = [
    { color: "#AB08FF", logo: "/img/logo-purple.svg" }, // Purple
    { color: "#1174B9", logo: "/img/logo-blue.svg" },   // Blue
    { color: "#FF2376", logo: "/img/logo-red.svg" }     // Red (updated color)
  ];

  const questionsAndAnswers = [
    { question: "what is musoplay?", answer: "it's a game that gets you playing music instantly regardless of your experience. it's fun, interactive, and accessible.", color: "#AB08FF" },
    { question: "is musoplay for me?", answer: "yes! whether you're learning music, exploring brain benefits or just curious, musoplay is for you! even if you don't play an instrument.", color: "#1174B9" },
    { question: "how does musoplay help my brain?", answer: "research shows that ear-training boosts brain areas linked to sound processing, memory, and creativity, stimulating multiple brain regions at once.", color: "#FF2376" },
    { question: "can musoplay help me learn an instrument?", answer: "absolutely, a trained musical ear is key to playing any instrument and musoplay helps you develop that essential skill.", color: "#AB08FF" },
    { question: "is musoplay available on all devices?", answer: "musoplay will be available on any internet-connected device as a web-based game, while we develop the app.", color: "#1174B9" },
    { question: "can i share my musoplay progress?", answer: "yes, you can easily share your progress with friends on social media making the game fun and social :-)", color: "#FF2376" },
    { question: "will i receive a lot of emails?", answer: "we'll only email you about major updates like the launch of the web-based game or app, we will never spam you, your data is safe with us.", color: "#AB08FF" },
    { question: "who made musoplay?", answer: "musoplay is the brainchild of edinburgh based musician and music teacher claire patricia campbell.", color: "#1174B9" }
  ];

  const QuestionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 75vw;
    max-width: 800px;
    height: auto;
    padding: 10px;
    gap: 15px;
    position: relative;
  `;

  const ExpandableBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    border: 5px solid ${props => props.borderColor};
    border-radius: 20px;
    padding: 8px;
    width: 90%;
    background-color: #fffdee;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;

    height: ${props => props.expanded ? 'auto' : '160px'};
    overflow: hidden;

    @media (max-width: 768px) {
      height: ${props => props.expanded ? 'auto' : '100px'};
    }
  `;

  const Icon = styled.img`
    height: 15vw;
    max-height: 160px;

    @media (min-width: 768px) {
      height: 10vw;
      max-height: 120px;
    }

    margin-left: 10px;
    margin-right: 15px;
  `;

  const QuestionText = styled.p`
    font-size: 5.5vw;
    font-family: 'Patrick Hand SC', cursive;
    line-height: 1.2;
    text-transform: lowercase;
    white-space: normal;
    
    @media (min-width: 768px) {
      font-size: 2.8rem;
    }
  `;

  const AnswerText = styled.p`
    font-size: 5vw;
    font-family: 'Patrick Hand SC', cursive;
    line-height: 1.4;
    margin-top: 10px;
    color: #555;
    
    @media (min-width: 768px) {
      font-size: 2rem;
    }

    display: ${props => props.expanded ? 'block' : 'none'};
    word-wrap: break-word;
  `;

  return (
    <QuestionsContainer>
      {questionsAndAnswers.map((qa, index) => (
        <ExpandableBox
          key={index}
          onClick={() => toggleExpand(index)}
          expanded={expandedIndex === index}
          borderColor={qa.color}
        >
          <Icon src={logoImages[index % logoImages.length].logo} alt="Musoplay Icon" />
          <div>
            <QuestionText>{qa.question}</QuestionText>
            <AnswerText expanded={expandedIndex === index}>
              {qa.answer}
            </AnswerText>
          </div>
        </ExpandableBox>
      ))}
    </QuestionsContainer>
  );
};

export default Questions;