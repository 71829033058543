import React from "react";
import styled from "styled-components";
import "./styles.css";

const cubeSideSrc = "/img/logo.svg";

const CubeContainer = styled.div`
  padding: 5vw;
  perspective: 1000px;
`;

const Cube = () => (
  <CubeContainer>
    <div className="cube">
      <div className="face front">
        <img src={cubeSideSrc} alt="Front" />
      </div>
      <div className="face back">
        <img src={cubeSideSrc} alt="Back" />
      </div>
      <div className="face top">
        <img src={cubeSideSrc} alt="Top" />
      </div>
      <div className="face bottom">
        <img src={cubeSideSrc} alt="Bottom" />
      </div>
      <div className="face left">
        <img src={cubeSideSrc} alt="Left" />
      </div>
      <div className="face right">
        <img src={cubeSideSrc} alt="Right" />
      </div>
    </div>
  </CubeContainer>
);

export default Cube;
