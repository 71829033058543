import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import "./styles.css";

const SocialMedia = () => {
  return (
    <section className="social-media">
      <div className="social-media-wrap">
        <div className="social-icons">
          <a
            className="social-icon-link facebook"
            href="https://www.facebook.com/musoplay"
            target="_blank"
            aria-label="Facebook"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
          <a
            className="social-icon-link instagram"
            href="https://www.instagram.com/musoplaygame/"
            target="_blank"
            aria-label="Instagram"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            className="social-icon-link tiktok"
            href="https://www.tiktok.com/@musoplay"
            target="_blank"
            aria-label="TikTok"
            rel="noreferrer"
          >
            <FaTiktok />
          </a>
          <a
            className="social-icon-link youtube"
            href="https://www.youtube.com/@musoplay"
            target="_blank"
            aria-label="Youtube"
            rel="noreferrer"
          >
            <FaYoutube />
          </a>
          <a
            className="social-icon-link linkedin"
            href="https://www.linkedin.com/in/claire-patricia-campbell-b35a8251/"
            target="_blank"
            aria-label="LinkedIn"
            rel="noreferrer"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>
    </section>
  );
};

export default SocialMedia;
