import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

// Keyframes for the subtle jump/pulse effect
const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

// Keyframes for flying in from the left and right
const flyInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const flyInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const ElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  gap: 10px;
  max-width: 700px;
  opacity: 0; /* Initially hidden */
  
  /* Spacing between elements */
  margin-bottom: 50px;

  /* Animation for flying in from the left or right */
  &.fly-in-left {
    animation: ${flyInLeft} 1s ease-out forwards;
  }

  &.fly-in-right {
    animation: ${flyInRight} 1s ease-out forwards;
  }

  .icon {
    width: 200px;
    height: 200px;
    cursor: pointer;
  }

  /* Apply pulse animation only to the purple icon after it lands */
  .pulse {
    animation: ${pulse} 0.6s ease-out 1.5s 2; /* Pulse effect with delay */
  }

  h3 {
    font-family: "Patrick Hand SC", cursive;
    font-size: 3.5rem; /* Increased header font size */
    margin: 0;
    text-transform: uppercase;
  }

  p {
    font-family: "Patrick Hand SC", cursive;
    font-size: 2rem; /* Increased body text size */
    margin: 0;
    color: #555;
    max-width: 600px;
    line-height: 1.6; /* Adjusted line height */
    text-align: center;
  }

  /* Media query for smaller screens */
  @media (max-width: 768px) {
    margin-bottom: 20px; /* Reduce the margin between elements */
    gap: 5px; /* Reduce the gap between image and text */
    
    .icon {
      width: 150px; /* Make the icons smaller for mobile */
      height: 150px;
    }

    h3 {
      font-size: 2.5rem; /* Reduce header size for mobile */
    }

    p {
      font-size: 1.5rem; /* Reduce body text size for mobile */
      line-height: 1.4; /* Reduce line height for mobile */
    }
  }
`;

const ScrollElement = ({ index, src, alt, title, description, isImage, pulseEffect }) => {
  const ref = useRef(null);
  const audioRef = useRef(new Audio("/sounds/ambience.mp3")); // Initialize the sound
  const [isPlaying, setIsPlaying] = useState(false); // Track if the sound is playing

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Apply the fly-in animation based on index (odd = left, even = right)
            if (index % 2 === 0) {
              entry.target.classList.add("fly-in-right");
            } else {
              entry.target.classList.add("fly-in-left");
            }

            // If it's the purple icon, add the pulse effect after the fly-in
            if (pulseEffect && entry.target.classList.contains('fly-in-left')) {
              setTimeout(() => {
                entry.target.querySelector('.icon').classList.add('pulse');
              }, 1000); // Delay the pulse animation to start after the fly-in completes
            }

            observer.unobserve(entry.target); // Unobserve after animation has triggered
          }
        });
      },
      { threshold: 0.1 } // Trigger animation when 10% of the element is visible
    );

    const current = ref.current;
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [index, pulseEffect]);

  // Toggle play/pause for the sound when the icon is clicked
  const handleIconClick = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((e) => console.log("Autoplay was prevented:", e));
    }
    setIsPlaying(!isPlaying); // Toggle the state
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    };

    const handlePageHide = () => {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('pagehide', handlePageHide);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('pagehide', handlePageHide);
    };
  }, [isPlaying]);

  return (
    <ElementContainer ref={ref}>
      {isImage ? (
        <img
          className={`icon ${pulseEffect ? "pulse" : ""}`} // Apply pulse class conditionally
          src={src}
          alt={alt}
          onClick={handleIconClick}
        />
      ) : (
        <>
          <h3>{title}</h3>
          <p>{description}</p>
        </>
      )}
    </ElementContainer>
  );
};

const ScrollSection = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "40px", padding: "30px" }}>
      {/* Purple icon with pulse effect */}
      <ScrollElement
        index={1}
        src="/img/info/icon-purple.svg"
        alt="Boost your brain"
        isImage={true}
        pulseEffect={true}  /* Enable pulse for this icon */
      />
      <ScrollElement
        index={2}
        title="boost your brain"
        description="sharpen your mind with musoplay's daily music challenge"
        isImage={false}
      />
      <ScrollElement
        index={3}
        src="/img/info/icon-blue.svg"
        alt="Play music by ear like a pro!"
        isImage={true}
        pulseEffect={false} /* No pulse for other icons */
      />
      <ScrollElement
        index={4}
        title="play music by ear like a pro!"
        description="master your musical ear, one melody at a time"
        isImage={false}
      />
      <ScrollElement
        index={5}
        src="/img/info/icon-red.svg"
        alt="No instrument? No problem!"
        isImage={true}
        pulseEffect={false} /* No pulse for other icons */
      />
      <ScrollElement
        index={6}
        title="no instrument? no problem!"
        description="perfect for those with or without an instrument, play anywhere, anytime!"
        isImage={false}
      />
    </div>
  );
};

export default ScrollSection;