import React from "react";
import "./App.css";
import Header from "./components/Header";
import Info from "./components/Info";
import Questions from "./components/Questions";
import Waitlist from "./components/Waitlist"; 
import Piano from "./components/Piano";
import Contact from "./components/Contact";
import Cube from "./components/Cube";
import SocialMedia from "./components/SocialMedia";

function App() {
  return (
    <div className="app-container">
      <Header />
      <Info />
      <Waitlist />
      <Questions />
      <Piano />
      <Cube />
      <Contact />
      <SocialMedia />
    </div>
  );
}

export default App;