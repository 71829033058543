import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaWhatsapp } from 'react-icons/fa';

// Define animations for WhatsApp icon and text typing
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const lineTyping = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

// Styled Components
const Image = styled.img`
  height: 12vw;
  width: auto;
  max-height: 120px;
`;

const LogoWaitlistContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: auto;
  gap: 0.2vw;
  padding-top: 10px;
`;

const WaitlistText = styled.h3`
  font-family: "Patrick Hand SC", cursive;
  margin: 0;
  color: #555;
  text-transform: lowercase;
  text-align: center;
  white-space: normal;
  font-size: 5vw;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const TypingText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Patrick Hand SC", cursive;
  color: #555;
  text-transform: lowercase;
  overflow: hidden;
  font-size: 5vw;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const FinalLine1 = styled.h3`
  margin: 0;
  font-size: inherit;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  animation: ${lineTyping} 2s steps(30, end) forwards;
`;

const FinalLine2 = styled.h3`
  margin: 0;
  font-size: inherit;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  animation: ${lineTyping} 2s steps(30, end) forwards;
  animation-delay: 2.5s;
`;

const WaitlistContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  width: 75vw;
  max-width: 800px;
  height: auto;
  padding: 10px 0;
  gap: 10px;
`;

const EmailInput = styled.input`
  width: 90%;
  height: 40px;
  border: 4px solid #AB08FF;
  background-color: #fffdee;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  text-transform: lowercase;

  @media (min-width: 768px) {
    font-size: 36px;
    width: 80%;
  }
`;

const ButtonContainer = styled.div`
  width: 40%;
  height: auto;

  .signup-button {
    font-size: min(36px, calc(3vw + 0.5%));
    padding: 5px;
    background-color: #1174b9;
    color: white;
    border: 5px solid #1174b9;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .signup-button:hover {
    background-color: #0056b3;
  }
`;

const WhatsAppIcon = styled(FaWhatsapp)`
  color: #00c22d;
  font-size: 6rem;
  animation: ${pulse} 1s infinite ease-in-out;
  cursor: pointer;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 1s ease-in;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

const Waitlist = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showWhatsApp, setShowWhatsApp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSignUpClick = async () => {
    console.log('Sign up button clicked');
    if (!email) {
      console.log('No email provided');
      setErrorMessage('Please enter an email address');
      return;
    }

    setLoading(true);
    setErrorMessage('');
    console.log('Attempting to submit email:', email);

    try {
      console.log('Sending fetch request to backend');
      const response = await fetch('https://musoplay-backend.onrender.com/submit-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      console.log('Received response from backend:', response);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}. Error: ${errorText}`);
      }

      const data = await response.json();
      console.log('Response data:', data);

      setLoading(false);
      setSubmitted(true);

      setTimeout(() => {
        setShowWhatsApp(true);
      }, 4500);
    } catch (error) {
      console.error('Error details:', error);
      setLoading(false);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <WaitlistContainer id="waitlist-section">
      <LogoWaitlistContainer>
        <Image src="/img/logo.svg" alt="Musoplay" />
        <Image src="/img/waitlist/waitlist.svg" alt="Waitlist" />
      </LogoWaitlistContainer>

      {!submitted ? (
        <>
          <WaitlistText>
            sign up now and we'll let you know<br />when the game goes live!
          </WaitlistText>
          <EmailInput
            type="email"
            placeholder="enter your email"
            value={email}
            onChange={handleEmailChange}
            required
            name="email"
            id="email-input"
          />
          <ButtonContainer>
            {loading ? (
              <button className="signup-button">Loading...</button>
            ) : (
              <button className="signup-button" onClick={handleSignUpClick}>
                sign up
              </button>
            )}
          </ButtonContainer>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </>
      ) : (
        <>
          <TypingText>
            <FinalLine1>thank you! we'll be in touch soon.</FinalLine1>
            <FinalLine2>now, share the musoplay love with a friend.</FinalLine2>
          </TypingText>
          <WhatsAppIcon
            visible={showWhatsApp}
            onClick={() =>
              window.open(
                `https://wa.me/?text=${encodeURIComponent(
                  'Check out Musoplay - the daily music game - An interactive experience that gets you playing music and having fun! Visit the website here: https://musoplay.com'
                )}`,
                '_blank'
              )
            }
          />
        </>
      )}
    </WaitlistContainer>
  );
};

export default Waitlist;