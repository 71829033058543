import React, { useState, useEffect, useRef, useCallback } from "react";
import "./styles.css";
import styled from "styled-components";

// Add this styled component for the text above the piano
const PianoText = styled.h3`
  font-family: "Patrick Hand SC", cursive;
  margin: 0;
  color: #555;
  text-transform: uppercase;
  text-align: center;
  white-space: normal; 
  font-size: 5vw;
  max-font-size: 3.5rem;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

function Piano() {
  const audioContextRef = useRef(null);
  const [samples, setSamples] = useState({});
  const [activeNotes, setActiveNotes] = useState(new Set());
  const clickCounter = useRef(0); // Use a ref to persist click count across renders

  useEffect(() => {
    // Scroll to the top when the page loads
    window.scrollTo(0, 0);

    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();

    const loadSample = async (url) => {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return audioContextRef.current.decodeAudioData(arrayBuffer);
    };

    const loadSamples = async () => {
      const samplePromises = [];
      for (let i = 1; i <= 8; i++) {
        samplePromises.push(loadSample(`/sounds/n${i}.mp3`));
      }
      const loadedSamples = await Promise.all(samplePromises);
      const sampleMap = loadedSamples.reduce((acc, sample, index) => {
        acc[`n${index + 1}`] = sample;
        return acc;
      }, {});
      setSamples(sampleMap);
    };

    loadSamples();

    const resumeAudioContext = () => {
      if (audioContextRef.current.state === "suspended") {
        audioContextRef.current.resume();
      }
    };

    window.addEventListener("mousedown", resumeAudioContext);
    window.addEventListener("keydown", resumeAudioContext);
    window.addEventListener("touchstart", resumeAudioContext);

    return () => {
      window.removeEventListener("mousedown", resumeAudioContext);
      window.removeEventListener("keydown", resumeAudioContext);
      window.removeEventListener("touchstart", resumeAudioContext);
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const playNote = useCallback(
    (note) => {
      if (samples[note]) {
        const audioContext = audioContextRef.current;

        if (audioContext.state === "suspended") {
          audioContext.resume();
        }

        const source = audioContext.createBufferSource();
        source.buffer = samples[note];
        source.connect(audioContext.destination);
        source.start(0);
      }
    },
    [samples]
  );

  const handleKeyPress = useCallback(
    (event) => {
      const keyMap = {
        // You can re-enable key mappings here if needed
      };

      const note = keyMap[event.key];
      if (note) {
        playNote(note);
        setActiveNotes((prev) => new Set(prev).add(note));
      }
    },
    [playNote]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  // Scroll to the waitlist section after every 8 clicks
  const handleScrollToWaitlist = () => {
    console.log("Scrolling to the waitlist...");
    const waitlistSection = document.getElementById("waitlist-section");

    if (waitlistSection) {
      const offsetTop = waitlistSection.getBoundingClientRect().top + window.pageYOffset;
      const additionalOffset = 190; // Reduced by 10px from the previous 200
      const scrollTarget = offsetTop - (window.innerHeight / 2) + (waitlistSection.offsetHeight / 2) - additionalOffset;
      window.scrollTo({
        top: scrollTarget,
        behavior: "smooth",
      });

      // Dispatch a custom event after scrolling back to the Waitlist
      const event = new CustomEvent("waitlist-scroll-back");
      window.dispatchEvent(event);

    } else {
      console.log("Waitlist section not found!");
    }
  };

  // Handle click on the piano keys
  const handleNoteClick = (note) => {
    playNote(note);
    clickCounter.current += 1;
    if (clickCounter.current % 8 === 0) {
      setTimeout(() => { // Delay to allow smooth state update
        handleScrollToWaitlist(); // Scroll to the waitlist
      }, 100); // 100ms delay
    }
  };

  return (
    <div className="container">
      {/* Add the text above the piano */}
      <PianoText>oooh...I wonder what these buttons do?</PianoText>
      
      <div className="button-grid">
        {[...Array(8).keys()].map((i) => (
          <button
            key={i}
            className={`note-button ${
              activeNotes.has(`n${i + 1}`) ? "active" : ""
            }`}
            onMouseDown={() => handleNoteClick(`n${i + 1}`)}
            onMouseUp={() =>
              setActiveNotes((prev) => {
                const newActiveNotes = new Set(prev);
                newActiveNotes.delete(`n${i + 1}`);
                return newActiveNotes;
              })
            }
            style={{
              background: `url(/img/piano/n${i + 1}.svg) no-repeat center`,
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default Piano;
