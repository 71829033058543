import React from "react";
import styled from "styled-components";

// Shared styled image for Logo and Musoplay to keep them the same size
const Image = styled.img`
  height: 12vw;
  width: auto;
  max-height: 120px;
`;

// Musoplay Component
const Musoplay = () => {
  return <Image src="/img/header/musoplay.svg" alt="Musoplay" />;
};

// Logo Component
const Logo = () => {
  return <Image src="/img/logo.svg" alt="Musoplay Logo" />;
};

// Container for the logo and Musoplay images
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: auto;
  gap: 1vw;
  padding-top: 10px;
`;

// Styled component for the slogan text
const SloganText = styled.h3`
  font-family: "Patrick Hand SC", cursive;
  margin: 0;
  color: #000;  /* Ensure the text is pure black */
  text-transform: uppercase;
  text-align: center;

  /* Responsive font size based on screen width */
  font-size: 7vw;
  max-font-size: 4rem; /* Increase the font size for larger screens */

  @media (min-width: 768px) {
    font-size: 3.5rem; /* Larger font size on tablet and bigger */
  }
`;

// Styled component for the new line of text, split into two lines
const SubText = styled.p`
  font-family: "Patrick Hand SC", cursive;
  color: #000;  /* Ensure the text is pure black */
  text-align: center;
  font-size: 5vw;
  margin: 5px 0;
  line-height: 1.3;  /* Adjust line spacing to keep the lines close */

  @media (min-width: 768px) {
    font-size: 2rem; /* Bigger on larger screens */
  }

  /* Ensure the text is displayed over two lines */
  white-space: pre-wrap;
`;

// Header container to keep the header fixed and styled
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: auto;
  background-color: #fffdee;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding-bottom: 10px;
`;

// Header component
const Header = () => {
  return (
    <HeaderContainer>
      <LogoContainer>
        <Logo />
        <Musoplay />
      </LogoContainer>
      {/* Replacing the slogan image with styled text */}
      <SloganText>The Daily Music Game</SloganText>
      {/* Adding the new sentence below, split into two lines */}
      <SubText>
        an interactive experience{"\n"}
        that gets you playing music and having fun!
      </SubText>
    </HeaderContainer>
  );
};

export default Header;
